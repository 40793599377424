import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const JeffEmslander = () => (
  <Layout
    title="3M Staff Scientist - Jeff Emslander"
    className="science-starters careers has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-work">Science at Work</Link>
          </li>
          <li className="breadcrumb active">Jeff Emslander</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">3M Staff Scientist</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../sara-frisco" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="292a41bd-c8d6-42e4-a9cc-6e60fecfb154"
                className="de-vid"
              ></Video>
              <Link to="../tesha-alston-dampier" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pb-6">
      <Container>
        <Row>
          <Column size={7} offset={1} className="career-left">
            <h2>Exploring New Frontiers in Scientific Innovation</h2>
            <p>
              As a 3M Staff Scientist, Jeff gets his hands on cutting-edge
              technologies and prototypes that most people never even see. Jeff
              is able to collaborate with a wide variety of experts who
              specialize in 40+ different technology platforms at 3M, while also
              travelling to other countries and learning from people from
              diverse cultures.
            </p>
            <h3>3 Things I Love About My Job</h3>
            <ol>
              <li>
                <strong className="text-bold">
                  Developing products that improve people’s lives in
                  groundbreaking ways.
                </strong>
                <br />
                The opportunity to make a positive impact in the world using the
                power of scientific innovation inspires Jeff every day and
                enhances his collaborations with teammates.
              </li>
              <li>
                <strong className="text-bold">
                  Hands-on access to advanced technology and specialists.
                </strong>
                <br /> Working in the 3M lab allows Jeff access to incredible
                technologies on a daily basis, while a big team of tech experts
                are always just a phone call away.
              </li>
              <li>
                <strong className="text-bold">
                  Collaborating with people all over the world.
                </strong>
                <br /> Science has opened many doors for Jeff throughout his
                career, allowing him the chance to work with many brilliant
                individuals and travel to new places around the world.
              </li>
            </ol>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Jeffrey-Emslander-mentor-quote.png" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “I get a chance to work with technologies and develop products
                  that no one’s ever done before in the history of the planet.”
                </h4>
                <h3>Jeff Emslander</h3>
                <p>3M Staff Scientist, Corporate Research Process Laboratory</p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default JeffEmslander;
